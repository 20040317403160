import { Icon } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { user } from '../app/reducers/user';
import headerlogo from '../assets/images/bg4.webp';
import bgimage from '../assets/images/bg7.jpg';


export function CardUI({children,header,subheader}){
	return(
		<div className='mt-[3vh] overflow-auto resize shadow-2xl rounded-2xl pb-4 bg-white bg-no-repeat bg-fixed bg-center' 
			style={{backgroundImage: `url(${bgimage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat',	backgroundPosition: 'center center' }} 
		>
			<CardNavigation/>
			{header && 
				<div className="relative overflow-hidden bg-no-repeat bg-cover h-32 rounded-t-lg mb-4" style={{backgroundPosition: '50%'}}>
					<img src={headerlogo} alt="" className="w-full" />
					<div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed" style={{backgroundColor: 'rgba(0,0,0,0.6)'}}>
						<div className="flex justify-center items-center h-full">
						<div className="text-white opacity-100 text-center">
							<h3 className="text-xl font-bold text-white">{header}</h3>
							{subheader && <p className="text-white">{subheader}</p>}
						</div>
						</div>
					</div>
				</div>
			}

			{/* <br /> */}
			
			{/* <div className="shadow-2xl rounded-lg flex p-5 flex-col justify-center items-center h-32 bg-gradient-to-r from-green-500 to-blue-500 mb-10">
				<h3 className="text-xl font-bold text-white">{header}</h3>
				{subheader && <p className="text-white">{subheader}</p>}
			</div> */}
			<div className='bg-white p-4'>
				{children}
			</div>
			

		</div>
    )
}

function CardNavigation(){
	const navigate = useNavigate()
	const userType = useSelector(user)

    return(
        <div className="px-1 pt-1 mb-3 max-w-[200px] bg-slate-100 shadow-lg rounded-l-2xl cursor-pointer">
				<div className="flex flex-row justify-center py-2">
					<div className="flex group">
						<span className="pr-3 text-black hover:text-blue-700 hover:scale-105" onClick={()=>navigate(-1)}>
							<span className="flex flex-col justify-center items-center">
								<Icon className="font-bold mx-1 group-hover:text-gray-700 transition-color duration-200">arrow_back</Icon>
								<span className="text-xs transition-all duration-200"> Go Back </span>
							</span>
						</span>
					</div>
					<div className="flex group border-l-4">
						<span className="pl-3 text-black hover:text-blue-700 hover:scale-105" onClick={()=>navigate(`/${userType.role}`)}>
							<span className="flex flex-col items-center">
								<Icon className="font-bold mx-1 group-hover:text-gray-700 transition-color duration-200">home</Icon>
								<span className="text-xs transition-all duration-200"> Go Back Home </span>
							</span>
						</span>
					</div>
				</div>
			</div>
    )
}