import { configureStore } from '@reduxjs/toolkit';
import { doctorAppointmentReducer } from './reducers/doctorappointments';
import { userReducer } from './reducers/user';


export const store = configureStore({
  reducer: {
    key: '',
    user: userReducer,
    doctorappointment: doctorAppointmentReducer
  },
});

