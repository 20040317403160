import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/onemedylogo.png";
import { user } from "../app/reducers/user";
import { useSelector } from "react-redux";

const LoadingScreen = () => {
  const navigate = useNavigate();
  const [callSpinner, setCallSpinner] = React.useState(false);
  const userType =  useSelector(user);


  React.useEffect(() => {
    setTimeout(() => {
      setCallSpinner(true);
    }, 4000);
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      if(userType.length !==0) navigate(`/${userType.role}`)
      else window.location.replace(`https://onemedy.com/login`)
    }, 12000);
  }, [callSpinner]);
  return (
    <div>
      {" "}
      <div className="Loading h-screen flex flex-col justify-center items-center relative text-white uppercase tracking-widest text-3xl">
        <img src={logo} alt="" className="w-52 logoLoading" />
        {/* Loading.... */}

        {callSpinner && (
          // <div className="absolute bottom-10 mx-auto">

          <span className="loader absolute bottom-10 mx-auto"></span>
          // </div>
        )}
      </div>
    </div>
  );
};

export default LoadingScreen;
