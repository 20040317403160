import axios from "axios";
import { useQuery, useQueryClient } from "react-query";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { loadProgressBar } from "axios-progress-bar";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      enabled: false,
      retry: 0,
      retryDelay: (attempt) =>
        Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      cacheTime: 24 * 60 * 60 * 1000,
      // onSettled: (data,error) => {data &&  error && console.log(error)},
    },
  },
});

export function ReactQuery({ children, persist }) {
  loadProgressBar();
  persist === true &&
    persistQueryClient({
      queryClient,
      persistor: createWebStoragePersistor({ storage: window.sessionStorage }),
    });

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {/* <ReactQueryDevtools initialIsOpen={false} position='bottom-right' /> */}
    </QueryClientProvider>
  );
}
// export const REQUEST_URI = `http://${window.location.hostname}:8001/api/v1`
export const REQUEST_URI = `https://api.onemedy.com/api/v1`
// export const REQUEST_URI = `http://localhost:7000/api/v1`


function XHR(method, url, userdata = null) {
  const token = localStorage.getItem("token") || null;

  const XHR_OBJECT = {
    url: url,
    method: method,
    baseURL: REQUEST_URI,
    headers: { Authorization: token ? `Bearer ${token}` : undefined },
    params: method === "get" ? userdata : undefined,
    data: method !== "get" ? userdata : undefined,
    timeout: 10000,
    onUploadProgress: function (progressEvent) {
      var { loaded, total } = progressEvent,
        percent = Math.floor((loaded * 100) / total);
      // console.log(`uploaded: ${loaded}kb of ${total}kb | ${percent}%`)
      // console.log(`${method} request to ${REQUEST_URI}/${url}.`)
    },
    onDownloadProgress: function (progressEvent) {
      var { loaded, total } = progressEvent,
        percent = Math.floor((loaded * 100) / total);
      // console.log(`downloaded: ${loaded}kb of ${total}kb | ${percent}%`)
    },
  };
  return XHR_OBJECT;
}

function XHRDebug(request = false, response = false) {
  request === true &&
    axios.interceptors.request.use(
      (req) => {
        var data = req.method === "get" ? req.params : req.data;
        console.log(
          `Request method: '${req.method}', to ${
            req.url
          }, with data: ${JSON.stringify(data, true)}`
        );
        return req;
      },
      (err) => {
        return Promise.reject(err);
      }
    );

  response === true &&
    axios.interceptors.response.use(
      (res) => {
        console.log(
          `Status: ${res.status}:${res.statusText} - Data: ${JSON.stringify(
            res.data,
            true
          )}`
        );
        return res;
      },
      (err) => {
        return Promise.reject(err);
      }
    );
}

export async function XHR_REQUEST(
  method,
  url,
  userdata = null,
  debugRequest = false,
  debugResponse = false
) {
  
  XHRDebug(debugRequest, debugResponse);
  // console.log(userdata)
  return await axios(XHR(method, url, userdata));
  
}

export const XHR_QUERY = {
  fetch: (
    queryName,
    method,
    url,
    userdata = null,
    debugRequest = false,
    debugResponse = false
  ) => {
    XHRDebug(debugRequest, debugResponse);
    return queryClient.fetchQuery(queryName, async () => {
      const { data } = await axios(XHR(method, url, userdata));
      return data;
    });
  },
  data: (queryName) => {
    return queryClient.getQueryData(queryName);
  },
  clear: () => queryClient.clear(),
};

export function useXHRRequest(
  queryName,
  method,
  url,
  userdata = null,
  debugRequest = false,
  debugResponse = false
) {
  XHRDebug(debugRequest, debugResponse);
  return useQuery(queryName, async () => {
    const { data } = await axios(XHR(method, url, userdata));
    return data;
  });
}

export function useXHRData(
  queryName,
  debugRequest = false,
  debugResponse = false
) {
  XHRDebug(debugRequest, debugResponse);
  const query = useQueryClient();
  return {
    fetch: query.fetchQuery(queryName),
    data: query.getQueryData(queryName),
    clear: query.clear(),
  };
}
