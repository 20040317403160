import { CardUI } from '../../CardUI';
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, TextField, Dialog, DialogContent, Pagination, Tooltip, Icon, IconButton} from '@mui/material'
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { XHR_REQUEST } from '../../../app/lib/request';
import { Notify } from '../../../app/lib/notification';
import { useLocation } from 'react-router';
import { Container, Row, Col } from 'react-grid-system';
import { useConfirm } from 'material-ui-confirm';


export function AdminLabView(){

    const { register, handleSubmit, formState:{errors}} = useForm();
    const [uploadFile, setUploadFile] = useState(null)
    const onSubmit = (data, e) => { 
        XHR_REQUEST('post','test/add',data)
        .then((res)=>{
            Notify('toast','New Test Created!','success');
            handleMClose()
            getTests()
        }).catch((err)=>{
            Notify('toast',err.response.data.message,'error');
        })
    }
    const onError = (errors, e) => console.log(errors, e); 


    const [page, setPage] = useState(1);
    const [tests, setTests] = useState()
    const handleChange = (event, value) => {
        getTests({page: value})
    };

    function getTests(data=''){
        XHR_REQUEST('get',`test/all`,data)
        .then((res)=>{
            setTests(res.data)
            setPage(res.data.current_page);
        }).catch((err)=>{
			Notify('toast',err.response.data.message,'error');
        })
    }

    const confirm = useConfirm();

    function disableTest(id,name){
        confirm({title:'Disable Tests', description: `Do you want to disable ${name}?` })
        .then(() => {
            XHR_REQUEST('delete',`test/delete/${id}`,)
            .then((res)=>{
                getTests({page: page})
                Notify('toast',`${name} has been disabled successfully!`,'success')
            }).catch((err)=>{
                Notify('toast',err.response.data.message,'error');
            })
        })
        .catch(() => { 
              Notify('toast','Action Cancelled!','info');
        });
    }

	const { pathname } = useLocation();
    useEffect(() => {
        getTests()
    }, [pathname])

    const [open, setOpen] = useState(false);
	const handleMOpen = () => { setOpen(true) };
	const handleMClose = () => { setOpen(false) };

    // function uploadTests(){
    //     if(uploadFile[0]?.name.split('.')[1] === 'csv'){
    //         var multipart = new FormData()
    //         multipart.append('drugs', uploadFile[0], uploadFile[0].name)
    //         XHR_REQUEST('post',`drugs/import`, multipart)
    //         .then((res)=>{
    //             Notify('toast','Drugs Uploaded','success');
    //             getDrugs({page: page})
    //         }).catch((err)=>{
    //             Notify('toast',err.response.data.message,'error');
    //         }) 
    //     }else{
    //         Notify('toast','File format is not CSV','error');
    //     }     
    // }


    return(
        <div className='mx-auto lg:max-w-[60vw] max-w-[80vw]'>
        <CardUI header='Tests' subheader='View All Tests'>
            <div className="overflow-auto shadow-lg rounded">
                    <div className="py-5 px-6 my-5 shadow bg-slate-50"> 
                        <p className="font-bold">All Tests 
                            {/* <label for='uploadDrugs' className='btn-primary mb-4 float-right ml-4'>Bulk Upload Drugs</label>  */}
                            <button onClick={handleMOpen} className="btn-primary float-right mb-4">Add New Test</button>
                        </p> 
                        
                        {/* <input type='file' className='hidden' id='uploadDrugs' accept='.csv' onChange={(e) => setUploadFile(e.target.files)}/> */}
                        {/* {uploadFile && <>File Name: {uploadFile[0]?.name}, File Size: {Math.ceil(uploadFile[0]?.size/1029)} KB  <button className='btn-primary m-4' onClick={uploadDrugs}>Upload Drugs List</button></>}  */}
                    </div>

                    <Dialog open={open} onClose={handleMClose}>
                        <DialogContent>
                        <form onSubmit={handleSubmit(onSubmit,onError)} >
                            <span className='font-bold'>New Test</span>
                            <Container>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <TextField fullWidth variant="standard" label="Test Name" {...register('name',{required:true,value:''})} error={!!errors.name} helperText={errors.name?.message}/>                                    
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <TextField fullWidth variant="standard" label="Description" {...register('description',{required:true,value:''})} error={!!errors.description} helperText={errors.description?.message}/>                                    
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <TextField fullWidth variant="standard" type='number' label="Amount" {...register('price',{required:true,value:''})} error={!!errors.amount} helperText={errors.amount?.message}/>                            
                                    </Col>
                                </Row>
                            </Container>
                            <div className='text-center mt-2'><button className="btn-primary mr-5">Add Test</button></div>
                        </form>
                        </DialogContent>
                    </Dialog>
            <TableContainer>
            <Table>
                <TableHead><TableRow><TableCell>S/N</TableCell><TableCell>Name</TableCell><TableCell>Description</TableCell><TableCell>Amount</TableCell><TableCell>Created At</TableCell><TableCell>Actions</TableCell></TableRow></TableHead>
                <TableBody>
                {tests?.length !==0 && tests?.map((items,index)=>{
                    return (
                        <TableRow key={index}>
                        <TableCell>{index+1} ID:{items.id}</TableCell>
                        <TableCell>{items.name}</TableCell>
                        <TableCell>{items.description}</TableCell>
                        <TableCell>{items.price}</TableCell>
                        <TableCell>{new Date(items.created_at).toDateString()}</TableCell>
                        <TableCell>
                            {/* <Tooltip title="Activate" followCursor>
                                <IconButton onClick={()=> enableDrug(items.id,items.name)}><Icon>visibility</Icon></IconButton>
                            </Tooltip>   */}                      
                            <Tooltip title="Deactivate" followCursor>
                                <IconButton onClick={()=> disableTest(items.id,items.name)}><Icon>disabled_visible</Icon></IconButton>
                            </Tooltip> 
                        </TableCell>
                        </TableRow>
                    )
                })}

                </TableBody>
            </Table>
            </TableContainer>
            <Pagination count={tests?.last_page} page={page} onChange={handleChange} color="primary"/>
            </div>
        </CardUI>
        </div>
    )
}


