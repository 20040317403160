import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import 'tw-elements';
import './index.css';
import 'photoswipe/style.css';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ConfirmProvider } from 'material-ui-confirm';


export const appType = 'web' //mobile

createRoot(document.getElementById('root'))
  .render(
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ConfirmProvider>
            <App/>
          </ConfirmProvider>
        </LocalizationProvider>
      </Provider>
  )

// If you want your app to work offline and load faster, you can change unregister() to register() below.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
reportWebVitals(console.log());
