
import onemedylogo from '../assets/images/onemedylogo.png'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { avatar, online, user, userdata } from '../app/reducers/user';
import { useEffect, useState } from 'react';
import { SwipeableDrawer, Drawer, Icon, Menu, MenuItem, Divider, Stack, Typography, Switch } from '@mui/material'
import { REQUEST_URI, XHR_REQUEST } from '../app/lib/request';
import { Notify } from '../app/lib/notification';
import { useUnloadBeacon } from '../app/lib/beacon';
import { usePageVisibility } from '../app/lib/visibility';


export default function DashboardNav (){

   const [notifications, setNotifications] = useState()
   const [totalUnread, setTotalUnread] = useState(0)
	const { pathname } = useLocation();
   const useronline = useSelector(online)
   const userType = useSelector(user);
   const myavatar = useSelector(avatar);
   const container = window !== undefined ? () => window.document.body : undefined;
   const [anchorEl2, setAnchorEl2] = useState(null);
   const nav = useNavigate()

   const [open, setOpen] = useState(false)
   const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

   // function getNotifications(){
   //    XHR_REQUEST('get',`chat/chat-list/${userType.id}`,)
   //    .then((res)=>{
   //       Notify('toast','Notifications Fecthed','success');
   //       setNotifications(res.data)
   //       var unread=0
   //       res.data?.forEach(notifs => {
   //          unread += notifs.unread_messages
   //       })
   //       setTotalUnread(unread)

   //    }).catch((err)=>{
   //          Notify('toast',err.response.data.message || 'Notifications Error','error');
   //    })         
   // }

	// useEffect(() => {
   //    getNotifications()         
   // }, [pathname])

   return(
    <>
      <div
        className={`lg:pl-[240px] flex flex-col min-h-[95vh] ${
          userType.role === "" ? "hidden" : ""
        }`}
      >

     <div className="h-16 w-full flex flex-wrap items-center justify-between py-2 bg-white hover:text-gray-700 focus:text-gray-700 shadow-lg navbar navbar-expand-lg navbar-light relative z-50">
        <div className="container-fluid w-full flex flex-wrap items-center justify-between px-6"> 
            <button className='md:hidden' onClick={()=>setOpen(true)}><Icon className='text-base font-semibold'>menu</Icon></button>
            <img src={onemedylogo} style={{height: '30px'}} alt="" loading="lazy" className='mx-auto'/>


               {/* FIXME: check notifications behavior and fix */}
               {/* {userType.role ==='doctor' && 
                  <span className='px-3' onClick={(e)=>setAnchorEl2(e.currentTarget)}><Icon>notifications</Icon> {totalUnread} </span>
               }
               {userType.role ==='user' && 
                  <span className='px-3' onClick={(e)=>setAnchorEl2(e.currentTarget)}><Icon>notifications</Icon> {totalUnread} </span>
               } */}

               <Menu anchorEl={anchorEl2} open={anchorEl2} onClose={()=>setAnchorEl2(null)} >
                  {totalUnread > 0 && notifications?.length !==0 ? notifications?.map((notifs,index)=>{
                     if(notifs.unread_messages < 10){
                        return(
                           <>
                           <MenuItem> 
                              <button key={index} className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2" 
                                 onClick={()=>{ nav(`/chat/${notifs?.id}/${(notifs?.firstname + ' ' + notifs?.lastname).toUpperCase()}`) }}
                              >
                                 <div className="ml-2 text-sm font-semibold"> {(notifs?.firstname + ' ' + notifs?.lastname).toUpperCase()}</div>
                                 <div className="flex items-center justify-center ml-auto text-xs bg-red-400 text-white h-4 w-4 rounded leading-none"> {notifs?.unread_messages} </div>
                              </button>
                           </MenuItem>
                           <Divider />
                           </>
                        )
                     }
                  }):<MenuItem> No New Notifications </MenuItem>
               }
               </Menu>
            
            {userType.role == 'doctor' && 
               <div>
                  <AvailabilitySwitch/>
               </div>}
            

            {userType.id !== undefined ? 
               useronline === 1 ?
                  <div className="flex items-center relative mr-2">
                     <span className="rounded-full text-white bg-green-700 font-semibold text-sm flex align-center cursor-pointer active:bg-gray-300 transition duration-300 ease w-max">
                        <img className="rounded-full w-11 h-11 max-w-none" alt="A" src={myavatar} />                  
                        <span className="flex items-center px-3 py-3 bg-green-700"> Chat: Online </span> 
                     </span>
                  </div>
               :null
            :null}
        </div>
      </div>
         <Outlet />
        <SwipeableDrawer container={container} variant="temporary" open={open} onClose={()=>setOpen(false)} onOpen={()=>setOpen(true)} ModalProps={{ keepMounted: true, }}
          disableBackdropTransition={!iOS} disableDiscovery={iOS} sx={{ display: { xs: 'block', sm: 'none' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240, backgroundColor: 'black' }, }}
        >
          <SlideNav usertype={userType.role}/>
        </SwipeableDrawer>

        <Drawer variant="permanent" open sx={{ display: { xs: 'none', sm: 'block' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 } }}>
           <SlideNav usertype={userType.role} tenant_id={userType.tenant_id}/>
        </Drawer>
        </div>
    </>
    )
}

function AvailabilitySwitch() {
   const [checked, setChecked] = useState(false)

   // TODO: beacon not sending
   // useUnloadBeacon(`${REQUEST_URI}/updatedoctor`,{availability:'offline'});

   const isVisible = usePageVisibility()

   useEffect(() => {
      if(checked){
         XHR_REQUEST('post','updatedoctor',{availability:'offline'})
         .then((res)=>{
            setChecked(false)
            Notify('toast','You are unavailable for consultation','info');
         }).catch((err)=>{
            Notify('toast',err.response.data.message,'error');
         })
      }
   }, [isVisible])
   

   function handleChange(){
      setChecked(!checked)
      XHR_REQUEST('post','updatedoctor',{availability:!checked ? 'online':'offline'})
      .then((res)=>{
         Notify('toast',!checked ? 'You are now available for consultation':'You are unavailable for consultation','info');
      }).catch((err)=>{
         setChecked(!checked)
         Notify('toast',err.response.data.message,'error');
      })
   }

   return (
   <Stack direction="row" spacing={1} alignItems="center">
      <Typography>Unavailable</Typography>
      <Switch
         checked={checked}
         onChange={handleChange}
         inputProps={{ 'aria-label': 'controlled' }}
      />
      <Typography>Available</Typography>
   </Stack>
   );
 }

function SlideNav({usertype, tenant_id}){
   const myavatar = useSelector(avatar);
   const userinfo = useSelector(userdata);
   const userType = useSelector(user);

   const admin=[
      {icon:'grid_view',link:'Dashboard',text:'',to:'/admin'},
      {icon:'account_circle',link:'Profile',text:'',to:'/admin/profile'},
      {icon:'manage_accounts',link:'Update Profile',text:'',to:'/admin/updateprofile'},
      {icon:'lock_reset',link:'Update Password',text:'',to:'/updatepass'},
      {icon:'people_outline',link:'Tenants',text:'',to:'/admin/tenants'},
      {icon:'accessible',link:'Patients',text:'',to:'/admin/users'},
      {icon:'local_hospital',link:'Doctors',text:'',to:'/admin/doctors'},
      {icon:'create_new_folder',link:'Appointments',text:'',to:'/admin/appointments'},
      {icon:'vaccines',link:'Drugs',text:'',to:'/admin/drugs'},
      {icon:'biotech',link:'Diagnostic Tests',text:'',to:'/admin/lab'},
      {icon:'credit_score',link:'Withdrawals',text:'',to:'/admin/withdrawals'},
      {icon:'payments',link:'Transactions',text:'',to:'/admin/transactions'},
      {icon:'account_balance',link:'Update Fees',text:'',to:'/admin/fees'},
      {icon:'groups2',link:'Specializations',text:'',to:'/admin/specs'},
   ]

   const doctornav=[
      {icon:'grid_view',link:'Dashboard',text:'',to:'/doctor'},
      {icon:'account_circle',link:'Profile',text:'',to:'/doctor/profile'},
      {icon:'manage_accounts',link:'Update Profile',text:'',to:'/doctor/updateprofile'},
      {icon:'camera_front',link:'Update Bio Data',text:'',to:'/doctor/updatebio'},
      {icon:'account_balance',link:'Update Account',text:'',to:'/doctor/updateaccount'},
      {icon:'lock_reset',link:'Update Password',text:'',to:'/updatepass'},
      {icon:'create_new_folder',link:'Appointments',text:'',to:'/doctor/appointments'},
      {icon:'chat',link:'All Chats',text:'',to:'/chat'},
      {icon:'credit_score',link:'Withdrawals',text:'',to:'/doctor/withdrawal'},
      {icon:'calendar_month',link:'Set Schedule',text:'',to:'/doctor/schedule'},
   ]
   
   const doctornavwithoutinfo=[
      {icon:'account_circle',link:'Profile',text:'',to:'/doctor/profile'},
      {icon:'manage_accounts',link:'Update Profile',text:'',to:'/doctor/updateprofile'},
      {icon:'camera_front',link:'Update Bio Data',text:'',to:'/doctor/updatebio'},
      {icon:'account_balance',link:'Update Account',text:'',to:'/doctor/updateaccount'}
   ]
   
   const patient=[
      {icon:'grid_view',link:'Dashboard',text:'',to:'/user'},
      {icon:'account_circle',link:'Profile',text:'',to:'/user/profile'},
      {icon:'manage_accounts',link:'Update Profile',text:'',to:'/user/updateprofile'},
      {icon:'camera_front',link:'Update Bio Data',text:'',to:'/user/updatebio'},
      {icon:'lock_reset',link:'Update Password',text:'',to:'/updatepass'},
      {icon:'create_new_folder',link:'Appointments',text:'',to:'/user/appointment'},
      {icon:'chat',link:'All Chats',text:'',to:'/chat'},
   ]

   const pharm=[
      {icon:'account_circle',link:'Profile',text:'',to:'/tenantuser/profile'},
      {icon:'manage_accounts',link:'Update Profile',text:'',to:'/tenantuser/updateprofile'},
      {icon:'lock_reset',link:'Update Password',text:'',to:'/updatepass'},
      {icon:'vaccines',link:'Pharmacy Requests',text:'',to:'/tenantuser/drugs'},
   ]

   const lab=[
      {icon:'account_circle',link:'Profile',text:'',to:'/tenantuser/profile'},
      {icon:'manage_accounts',link:'Update Profile',text:'',to:'/tenantuser/updateprofile'},
      {icon:'lock_reset',link:'Update Password',text:'',to:'/updatepass'},
      {icon:'biotech',link:'Diagnosis Requests',text:'',to:'/tenantuser/lab'},
   ]


   const [doctor, setDoctor] = useState(doctornav)
   // const [tenant, setTenant] = useState(tenantnav)
   const { pathname } = useLocation()


   useEffect(() => {

      userType.role === 'doctor' &&
      XHR_REQUEST('get',`doctordetail/user/${userType.id}`)
            .then((res)=>{
               
               res.data.avater === '' && Notify('toast','Update Your User Image','info');
               res.data.specialization.length === '' && Notify('toast','Update Your Specialization','info');
               res.data.folio_id === '' && Notify('toast','Update Your Folio Number','info');
               res.data.qualification === '' && Notify('toast','Update Your Qualification','info');

               // if(!res.data.avater){
               //    Notify('toast','You need to update your image!','info');
               //    setDoctor(doctornavwithoutinfo)
               // }else 
               if(res.data.specialization.length === 0 || !res.data.folio_id || !res.data.qualification || !res.data.school_of_medicine ){
                  Notify('toast','You need to update your information!','info');
                  setDoctor(doctornavwithoutinfo)
               }else{
                  setDoctor(doctornav)
               }
            }).catch((err)=>{
                Notify('toast',err.response.data.message,'error');
                setDoctor(doctornavwithoutinfo)
            })
   }, [pathname, userinfo])

   
    return(
      <>
      {usertype!== undefined && 
         <div className="bg-white rounded overflow-hidden shadow-lg">
               <div className="text-center p-6  border-b"> 
               <img className="h-24 w-24 rounded-full mx-auto" src={myavatar} alt=""/>
                  <p className="pt-2 text-lg font-semibold"> {(userinfo?.firstname +' '+ userinfo?.lastname).toUpperCase()} </p>
                  {userinfo?.roles?.name === 'admin' && <p className="text-sm text-gray-600"> ADMIN </p>}
                  {userinfo?.roles?.name === 'doctor' && <p className="text-sm text-gray-600"> DOCTOR </p>}
                  {userinfo?.roles?.name === 'user' && <p className="text-sm text-gray-600"> PATIENT </p>}
                  {userinfo?.tenant_member && userinfo?.tenant_member.tenant.tenant_type_id === 1 ? <p className="text-sm text-gray-600"> PHARMACY </p> : <p className="text-sm text-gray-600"> DIAGNOSTIC CENTER </p>}                  
                  {/* {userinfo?.roles?.name === 'tenantuser' && <p className="text-sm text-gray-600"> TENANT </p>} */}
                  {userinfo?.tenant_member && <p className="text-sm text-gray-600 uppercase"> {userinfo?.tenant_member?.tenant?.name} </p>}  

               </div>
         </div>
      }

         <div className="relative flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white pt-0">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
               <div className="flex-1 px-3 bg-white divide-y space-y-1">
                  <div className="border-b">
                  <ul className="space-y-2 pb-2">
                     <li className='sidenav-close'>
                        <a href="https://onemedy.com">
                           <span className="text-base text-gray-900 font-normal rounded-lg flex items-center p-2 hover:bg-gray-100 group">
                              <Icon>home</Icon> <span className="ml-3">Go To Home Page</span>
                           </span>
                        </a>
                     </li>

                     {usertype==='admin' && admin.map( (items,index) => <MenuLink {...items} key={index}/>)}
                     {usertype==='doctor' && doctor.map( (items,index) => <MenuLink {...items} key={index}/>)}
                     {usertype==='user' && patient.map( (items,index) => <MenuLink {...items} key={index}/>)}
                     {tenant_id===1 && pharm.map( (items,index) => <MenuLink {...items} key={index}/>)}
                     {tenant_id===2 && lab.map( (items,index) => <MenuLink {...items} key={index}/>)}
                     {/* {usertype==='tenantuser' && tenant.map( (items,index) => <MenuLink {...items} key={index}/>)} */}
                     {usertype!== undefined
                        ? <>
                           <MenuLink icon='logout' link='Sign Out' to='signout' /> 
                           <MenuLink icon='chevron_right' link='Debug On' to='debug/1' /> 
                           </>
                        : <MenuLink icon='login' link='Sign In' to='landing' /> 
                     }
                  </ul>
                  </div>
               </div>
            </div>
         </div>
         
      </>
    )
}


function MenuLink({icon=null,link,text=null,to,}){
   return(
      <li className='sidenav-close'>
         <Link to={to}>
            <span className="text-base text-gray-900 font-normal rounded-lg flex items-center p-2 hover:bg-gray-100 group">
               <Icon>{icon}</Icon> <span className="ml-3">{link}</span>
            </span>
         </Link>
      </li>
   )
}

export function DashboardFooter(){
    return(
      
        <footer className="bg-white md:flex md:items-center md:justify-between shadow rounded-lg p-4 md:p-6 xl:p-8 my-6 mx-4 sticky top-[100vh]">
            <ul className="flex items-center flex-wrap mb-6 md:mb-0">
            <li><span className="text-sm font-normal text-gray-500 hover:underline mr-4 md:mr-6">Terms and conditions</span></li>
            <li><span className="text-sm font-normal text-gray-500 hover:underline mr-4 md:mr-6">Privacy Policy</span></li>
            <li><span className="text-sm font-normal text-gray-500 hover:underline mr-4 md:mr-6">Licensing</span></li>
            <li><span className="text-sm font-normal text-gray-500 hover:underline">Contact</span></li>
            </ul>
        </footer>
      
    )
}




