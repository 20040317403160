import React from "react";
import { useParams } from "react-router-dom";
import { AiOutlineClose, AiOutlineWechat } from "react-icons/ai";
import { FaNotesMedical } from "react-icons/fa";
import { Box, Modal } from "@mui/material";

export default function DocSpecialization() {
  const { id } = useParams();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  return (
    <section className="min-h-screen">
      <div className="grid xl:grid-cols-3 md:grid-cols-2 gap-4 p-5">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((card) => (
          <div
            key={card}
            onClick={handleOpen}
            class="max-w-xl w-full mx-auto z-10 "
          >
            <div class="flex flex-col">
              <div class="bg-white border border-white hover:shadow-lg transition-all cursor-pointer rounded-3xl p-4">
                <div class="flex-none sm:flex">
                  <div class=" relative h-32 w-32   sm:mb-0 mb-3">
                    <img
                      src="https://tailwindcomponents.com/storage/avatars/njkIbPhyZCftc4g9XbMWwVsa7aGVPajYLRXhEeoo.jpg"
                      alt="aji"
                      class=" w-32 h-32 object-cover rounded-2xl"
                    />
                  </div>
                  <div class="flex-auto sm:ml-5 justify-evenly">
                    <div class="flex items-center justify-between sm:mt-2">
                      <div class="flex items-center">
                        <div class="flex flex-col">
                          <div class="w-full flex-none text-lg text-gray-800 font-bold leading-none">
                            Dr Joshua Clifford
                          </div>
                          <div class="flex-auto text-gray-500 my-1">
                            <span class="mr-3 ">{id}</span>
                            <span class="mr-3 border-r border-gray-200  max-h-0"></span>
                            <span>Abuja, Gwarimpa</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-row items-center">
                      <div class="flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="h-5 w-5 text-yellow-500"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="h-5 w-5 text-yellow-500"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="h-5 w-5 text-yellow-500"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="h-5 w-5 text-yellow-500"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          class="h-5 w-5 text-yellow-500"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <DocInfo setOpen={setOpen} open={open} id={id} />
      </div>
    </section>
  );
}

const DocInfo = ({ open, setOpen, id }) => {
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: 3,
    boxShadow: 24,
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{}}
      >
        <Box sx={style} className="md:w-[500px] w-[350px] overflow-y-auto">
          {/* <div className="flex justify-end">
            <span className="h-7 w-7 rounded-full border-2 flex cursor-pointer p-1 justify-center items-center border-blue-500">
              <AiOutlineClose onClick={handleClose} size={30} />
            </span>
          </div> */}
          <div class="bg-white pb-6 w-full justify-center items-center overflow-hidden rounded-lg shadow-sm mx-auto">
            <div class="relative h-40">
              <img
                class="absolute h-full w-full object-cover"
                src="https://images.unsplash.com/photo-1448932133140-b4045783ed9e?ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=80"
              />
            </div>
            <div class="relative shadow mx-auto h-24 w-24 -my-12 border-white rounded-full overflow-hidden border-4">
              <img
                class="object-cover w-full h-full"
                src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=200&q=80"
              />
            </div>
            <div class="mt-16">
              <h1 class="text-lg text-center font-semibold">Dr Cassie</h1>
              <p class="text-sm text-gray-600 text-center">{id}</p>
            </div>
            <div class="mt-6 pt-3 flex flex-wrap mx-6 border-t">
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quia
                exercitationem nobis quis aliquid impedit, aut vero autem
                possimus animi ducimus aspernatur ut corrupti odio qui.
                Perferendis nobis nihil maxime voluptatum.
              </p>
            </div>
            <div class="mt-6 pt-3 flex justify-between mx-6 border-t">
              <div class="flex items-center space-x-4 md:mr-2 my-1 text-xs md:text-base  uppercase tracking-wider transition-all rounded-xl md:px-5 px-3  text-green-600  hover:bg-green-600 hover:text-green-100 cursor-pointer py-1">
                <AiOutlineWechat size={23} className="md:mr-2 mr-1" />
                Chat With Doctor
              </div>
              <div class="flex items-center space-x-4 text-xs md:text-base  md:mr-2 my-1 uppercase tracking-wider border rounded-xl py-1 md:px-5 px-3 border-indigo-600 bg-indigo-600 text-indigo-100 cursor-pointer">
                <FaNotesMedical size={23} className="md:mr-2 mr-1" />
                Order Doctor
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
