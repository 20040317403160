import { Rating } from "@mui/material"
import { useEffect, useState, useRef } from "react"
import { useParams } from "react-router-dom"
import { useReactToPrint } from "react-to-print"
import { Notify } from "../../app/lib/notification"
import { XHR_REQUEST } from "../../app/lib/request"
import { CardUI } from "../CardUI"


export function MedicalHistory(){
    const { id } = useParams()
    const [medicalHistory, setMedicalHistory] = useState([])
    // const { path }

    const allHistory = useRef();
    const printHistory = useReactToPrint({
        content: () => allHistory.current,
    });

    useEffect(() => {
        XHR_REQUEST('get',`medical-history/${id}`)
        .then((res)=>{
            setMedicalHistory(res.data)
            Notify('toast','History Loaded!','success');
        }).catch((err)=>{
            Notify('toast',err.response.data.message,'error');
        })
    }, [])

    return(
        <div className='mx-auto lg:max-w-[60vw] max-w-[80vw]'>
        <CardUI header='Medical History' subheader='View All Appointments Information'>
            <div ref={allHistory}>
            {medicalHistory.map((data,index) => (
                <div key={index} className="p-3 m-3 shadow-2xl rounded-2xl shadow-slate-500 grid sm:grid-cols-1 md:grid-cols-2 gap-3 my-5">
                    <div>
                        <p className="underline mb-2 font-semibold">Appointment Information</p>
                        <p>Created on: {new Date(data.created_at).toDateString()}</p>
                        <p>Appontment Day: {data.day} at {data.starts_at}</p>
                        <p>Consultation Fee: N{data.amount}</p>
                        <p>Status: {data.status}</p>
                        <p>Appointment Rating: <Rating size="small" name="half-rating" value={data.rated} precision={1} /></p>
                        <p>Health Issue: {data.appointmentdiagnosis?.healthchallenges}</p>
                    </div>
                    <div>
                        <p className="underline mb-2 font-semibold">Doctor Information</p>
                        <p>Name: {data.doctor?.users?.firstname} {data.doctor?.users?.lastname}</p>
                        <p>Phone Number: {data.doctor?.users?.phonenumber}</p>
                        <p>Email: {data.doctor?.users?.email}</p>
                        <p>Doctor Notes: {data.note}</p>
                        <p>Conclusion: {data.appointmentdiagnosis?.conclusion}</p>
                    </div>
                    <div>
                        <p className="underline mb-2 font-semibold">Medication</p>
                        <div className=" grid md:grid-cols-2 gap-3">
                        {data?.drug_prescription_item !== null && data?.drug_prescription_item?.map((drug,i) => (
                            <div key={i}>
                                <p>Drug: {drug.name}</p>
                                <p>Prescription: {drug.dosage_description}</p>
                                {/* <p>Price: N{drug.amount}</p> */}
                            </div>
                        ))}
                        </div>
                    </div>
                    <div>
                        <p className="underline mb-2 font-semibold">Diagnostic Tests</p>
                        <div className=" grid md:grid-cols-2 gap-3">
                        {data?.labtest !== null && data?.labtest?.map((test,i) => (
                            <div key={i}>
                                <p>Title: {test.title}</p>
                                <p>Description: {test.description}</p>
                                <p>Result: {test.result}</p>
                                {/* <p>Price: N{test.result}</p> */}
                            </div>
                        ))}
                        </div>
                    </div>
                    
                </div>
                )
            )}
            </div>
            <div className="flex justify-center">
                <button className="btn-primary w-56" onClick={printHistory}>Print History</button>
            </div>
            
        </CardUI>
        </div>
    )
}