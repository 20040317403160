import { createSlice } from '@reduxjs/toolkit';

export const doctorAppointmentSlice = createSlice({
    name: 'doctorappointment',
    initialState: {appointment:''},
    reducers: {
        addappointment: (state,action) => { state.appointment = action.payload },
    },
})


export const appointment = (state) => state.doctorappointment.appointment
export const { addappointment } = doctorAppointmentSlice.actions
export const doctorAppointmentReducer = doctorAppointmentSlice.reducer 