import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  useLocation,
  useNavigate,
  HashRouter,
} from "react-router-dom";
import { lazyWithPreload } from "./app/lib/lazyWithPreload";
import { useDispatch, useSelector } from "react-redux";
import { setdebug, user } from "./app/reducers/user";
import { Notifications } from "./app/lib/notification";
import { useEffect } from "react";

import MedicalHistoryPage from "./pages/MedicalHistoryPage";
import AdminLabPage from "./pages/admin/LabPage";
import DashboardNav from "./components/Nav";
import LoadingScreen from "./pages/LoadingScreen";
import Specialization from "./components/views/doctors/Specialization";
import DocSpecialization from "./components/views/doctors/DocSpecialization";

const LoadingUI = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./components/Loadables"
  )
);
const IndexPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/auth/Index"
  )
);
const UpdatePasswordPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/auth/UpdatePasswordPage"
  )
);
const WebMeetingPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/WebMeeting"
  )
);
const WebChatPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/WebChat"
  )
);
const AdminDashboardPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/admin/DashboardPage"
  )
);
const AdminProfilePage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/admin/ProfilePage"
  )
);
const AdminProfileFormPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/admin/ProfileFormPage"
  )
);
const AdminTenantPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/admin/TenantPage"
  )
);
const AdminUsersPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/admin/UsersPage"
  )
);
const AdminAppointmentsPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/admin/AppointmentsPage"
  )
);
const AdminWithdrawalPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/admin/WithdrawalPage"
  )
);
const AdminTransactionPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/admin/TransactionsPage"
  )
);
const AdminDrugsPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/admin/DrugsPage"
  )
);
const AdminDoctorsPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/admin/DoctorsPage"
  )
);
const AdminAppointmentFeesPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/admin/AppointmentFeesPage"
  )
);
const AdminSpecializationPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/admin/SpecializationPage"
  )
);
const DoctorDashboardPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/doctor/DashboardPage"
  )
);
const DoctorProfilePage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/doctor/ProfilePage"
  )
);
const DoctorProfileFormPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/doctor/ProfileFormPage"
  )
);
const DoctorBioFormPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/doctor/BioFormPage"
  )
);
const DoctorAccountFormPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/doctor/AccountFormPage"
  )
);
const DoctorAppointmentsPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/doctor/AppointmentsPage"
  )
);
const DoctorAddLabPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/doctor/AddLabPage"
  )
);
const DoctorViewLabPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/doctor/ViewLabPage"
  )
);
const DoctorDrugsPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/doctor/DrugsPage"
  )
);
const DoctorDiagnosisPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/doctor/DiagnosisPage"
  )
);
const DoctorRecordsPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/doctor/Records"
  )
);
const DoctorWithdrawalPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/doctor/WithdrawalPage"
  )
);
const DoctorScheduleSettingsPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/doctor/AppointmentSchedulePage"
  )
);
const PatientDashboardPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/patient/DashboardPage"
  )
);
const PatientProfilePage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/patient/ProfilePage"
  )
);
const PatientProfileFormPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/patient/ProfileFormPage"
  )
);
const PatientAppointmentsPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/patient/AppointmentsPage"
  )
);
const PatientBioFormPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/patient/BioFormPage"
  )
);
const PatientLabPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/patient/LabPage"
  )
);
const PatientDrugsPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/patient/DrugsPage"
  )
);
const PatientDiagnosisPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/patient/DiagnosisPage"
  )
);
const TenantProfilePage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/tenant/ProfilePage"
  )
);
const TenantProfileFormPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/tenant/ProfileFormPage"
  )
);
const TenantLabPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/tenant/LabPage"
  )
);
const TenantDrugsPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/tenant/DrugsPage"
  )
);
const Signout = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/auth/Signout"
  )
);
const Signin = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/auth/Signin"
  )
);
const Auth = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/auth/Auth"
  )
);
const GoToLandingPage = lazyWithPreload(() =>
  import(
    /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "./pages/auth/Landing"
  )
);

export default function App() {
  const userType = useSelector(user);

  return (
    // <div style={{backgroundImage: `url(${bgimage})`}} className='bg-no-repeat bg-fixed bg-center'>
    <div className="bg-slate-100">
      <HashRouter>
        <LoadingUI>
          <Notifications />
          <Auth />
          <Routes>
            <Route path="/">
              <Route index element={<LoadingScreen />} />
              <Route path="signin/:tokenid" element={<Signin />} />
              <Route element={<DashboardNav />}>
                {userType.role === "admin" && (
                  <Route path="admin">
                    <Route index element={<AdminDashboardPage />} />
                    <Route path="profile" element={<AdminProfilePage />} />
                    <Route
                      path="updateprofile"
                      element={<AdminProfileFormPage />}
                    />
                    <Route path="tenants" element={<AdminTenantPage />} />
                    <Route path="users" element={<AdminUsersPage />} />
                    <Route
                      path="appointments"
                      element={<AdminAppointmentsPage />}
                    />
                    <Route
                      path="withdrawals"
                      element={<AdminWithdrawalPage />}
                    />
                    <Route
                      path="transactions"
                      element={<AdminTransactionPage />}
                    />
                    <Route path="drugs" element={<AdminDrugsPage />} />
                    <Route path="lab" element={<AdminLabPage />} />
                    <Route path="doctors" element={<AdminDoctorsPage />} />
                    <Route path="fees" element={<AdminAppointmentFeesPage />} />
                    <Route path="specs" element={<AdminSpecializationPage />} />
                  </Route>
                )}
                {userType.role === "doctor" && (
                  <Route path="doctor">
                    <Route index element={<DoctorDashboardPage />} />
                    <Route path="profile" element={<DoctorProfilePage />} />
                    <Route
                      path="updateprofile"
                      element={<DoctorProfileFormPage />}
                    />
                    <Route path="updatebio" element={<DoctorBioFormPage />} />
                    <Route
                      path="updateaccount"
                      element={<DoctorAccountFormPage />}
                    />
                    <Route
                      path="appointments"
                      element={<DoctorAppointmentsPage />}
                    />
                    <Route path="lab" element={<DoctorAddLabPage />} />
                    <Route
                      path="viewtest"
                      children={
                        <Route path=":id" element={<DoctorViewLabPage />} />
                      }
                    />
                    <Route path="drugs" element={<DoctorDrugsPage />} />
                    <Route path="diagnosis" element={<DoctorDiagnosisPage />} />
                    <Route path="records" element={<DoctorRecordsPage />} />
                    <Route
                      path="withdrawal"
                      element={<DoctorWithdrawalPage />}
                    />
                    <Route
                      path="schedule"
                      element={<DoctorScheduleSettingsPage />}
                    />
                    <Route
                      path="medical-history/:id"
                      element={<MedicalHistoryPage />}
                    ></Route>
                  </Route>
                )}
                {userType.role === "user" && (
                  <Route path="user">
                    <Route index element={<PatientDashboardPage />} />
                    <Route path="profile" element={<PatientProfilePage />} />
                    <Route
                      path="updateprofile"
                      element={<PatientProfileFormPage />}
                    />
                    <Route path="updatebio" element={<PatientBioFormPage />} />
                    <Route
                      path="appointment"
                      element={<PatientAppointmentsPage />}
                    />
                    <Route
                      path="lab"
                      children={
                        <Route path=":id" element={<PatientLabPage />} />
                      }
                    />
                    <Route
                      path="drugs"
                      children={
                        <Route path=":id" element={<PatientDrugsPage />} />
                      }
                    />
                    <Route
                      path="diagnosis"
                      children={
                        <Route path=":id" element={<PatientDiagnosisPage />} />
                      }
                    />
                    <Route
                      path="medical-history/:id"
                      element={<MedicalHistoryPage />}
                    ></Route>
                  </Route>
                )}
                {userType.role === "tenantuser" && (
                  <Route path="tenantuser">
                    <Route index element={<TenantProfilePage />} />
                    <Route path="profile" element={<TenantProfilePage />} />
                    <Route
                      path="updateprofile"
                      element={<TenantProfileFormPage />}
                    />
                    <Route path="lab" element={<TenantLabPage />} />
                    <Route path="drugs" element={<TenantDrugsPage />} />
                  </Route>
                )}
                {userType.role && (
                  <>
                    <Route path="updatepass" element={<UpdatePasswordPage />} />
                    <Route
                      path="meeting/:appointmentid/:sendername/:receivername"
                      element={<WebMeetingPage />}
                    />
                    <Route path="chat" element={<WebChatPage />} />
                    <Route path="chat/:id/:name" element={<WebChatPage />} />
                  </>
                )}
              </Route>

              <Route path="signout" element={<Signout />} />
              <Route path="landing" element={<GoToLandingPage />} />
            </Route>
          </Routes>
        </LoadingUI>
      </HashRouter>
    </div>
  );
}

function SetDebug() {
  const { debugstate } = useParams();
  const { pathname } = useLocation();
  const nav = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setdebug(Number(debugstate)));
    nav(-1);
  }, [pathname]);
}
