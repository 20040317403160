import { useEffect, useState } from "react";

export function useVisibility({payload = () => {}}){
    useEffect(() => {
        window.addEventListener("focus", payload());
        window.addEventListener("blur", payload());
    }, []);
}


export function usePageVisibility() {
    function getBrowserVisibilityProp() {
        if (typeof document.hidden !== "undefined") {
            return "visibilitychange"
        } else if (typeof document.msHidden !== "undefined") {
            return "msvisibilitychange"
        } else if (typeof document.webkitHidden !== "undefined") {
            return "webkitvisibilitychange"
        }
    }
    
    function getBrowserDocumentHiddenProp() {
        if (typeof document.hidden !== "undefined") {
            return "hidden"
        } else if (typeof document.msHidden !== "undefined") {
            return "msHidden"
        } else if (typeof document.webkitHidden !== "undefined") {
            return "webkitHidden"
        }
    }
    
    function getIsDocumentHidden() {
        return !document[getBrowserDocumentHiddenProp()]
    }

    const [isVisible, setIsVisible] = useState(getIsDocumentHidden())
    const onVisibilityChange = () => setIsVisible(getIsDocumentHidden())

    useEffect(() => {
        const visibilityChange = getBrowserVisibilityProp()
        document.addEventListener(visibilityChange, onVisibilityChange, false)
    })

    return isVisible
}